import { SharedoFetch, SharedoProfile } from '@sharedo/mobile-core'

function getMyInstructions(rpp, p)
{
    return SharedoFetch.post("/api/v1/public/workItem/findByQuery",
    {
        search:
        {
            page: { rowsPerPage: rpp, page: p },
            sort: {
                orderBy: "createdDate",
                direction: "descending"
            },
            types: 
            { 
                includeTypesDerivedFrom: [
                    "vm-instruction-dispute-claimant-pd",
                ]
            },
            phase:
            {
                includeClosed: false,
                includeRemoved: false,
                inPhaseWithFeature: "show-vm-engineer-job-list",
            },
            ownership:
            {
                myScope:
                {
                    ownerIds:[SharedoProfile.profile.userId],
                    primary: true,
                    secondary: true
                }
            }
        },
        enrich:
        [
            { path: "reference" },
            { path: "title" },
            { path: "type.name" },
            { path: "type.iconClass" },
            { path: "phase.name" },
            { path: "roles.vm-engineer.ods.name" },
            { path: "createdDate.utc.value"},
            { path: "keyDates.kd-instruction-date.taskDueDate.date.local.value" },
            { path: "documents!1.title" },
            { path: "incidentDetailsLocation.location.formatted" },
            { path: "form-b269621a-e7eb-4e97-bac9-237b99af0f04-RemedyDetails.vm-reference-text" },  // Remedy number
        ]
    });
}

function getInstruction(id)
{
    if( !id ) throw "getInstruction: no id";

    return SharedoFetch.get(`/api/v1/public/workItem/${id}`);
}

function getInstructionEnriched(id)
{
    if( !id ) throw "getInstruction: no id";

    return SharedoFetch.post("/api/v1/public/workItem/findByQuery",
    {
        search:
        {
            page: { rowsPerPage: 1, page: 1 },
            workItemIds: [ id ],
        },
        enrich:
        [
            { path: "reference" },
            { path: "title" },
            { path: "description.html" },
            { path: "type.systemName" },
            { path: "type.name" },
            { path: "type.iconClass" },
            { path: "phase.name" },
            { path: "roles.vm-engineer.ods.name" },
            { path: "keyDates.kd-instruction-date.taskDueDate.date.local.value" },
            { path: "incidentDetailsLocation.location.formatted" },
            { path: "incidentDetailsLocation.location.address.line1"},
            { path: "incidentDetailsLocation.location.address.line2"},
            { path: "incidentDetailsLocation.location.address.line3"},
            { path: "incidentDetailsLocation.location.address.line4"},
            { path: "incidentDetailsLocation.location.address.town"},
            { path: "incidentDetailsLocation.location.address.county"},
            { path: "incidentDetailsLocation.location.address.country.name"},
            { path: "incidentDetailsLocation.location.address.postcode"},
            { path: "incidentDetailsLocation.location.coords.lat" },
            { path: "incidentDetailsLocation.location.coords.long" },
            { path: "form-b269621a-e7eb-4e97-bac9-237b99af0f04-RemedyDetails.vm-reference-text" },  // Remedy number

            // Incident details
            { path: "incidentDetailsCause.cause.id" },
            { path: "incidentDetailsCause.cause.name" },
            { path: "incidentDetailsType.type.id" },
            { path: "incidentDetailsType.type.name" },
            { path: "incidentDetailsDescription.description.html" },
            { path: "form-bff79397-a148-4088-b7bd-a38b2f3be6ce-IncidentDetailsExtended.vm-incident-details-extended-sub-loc-lov.id" },      // Sub location
            { path: "form-bff79397-a148-4088-b7bd-a38b2f3be6ce-IncidentDetailsExtended.vm-incident-details-extended-sub-loc-lov.name" },
            { path: "form-bff79397-a148-4088-b7bd-a38b2f3be6ce-IncidentDetailsExtended.vm-incident-mandatory-fields-completed-at" },
            { path: "form-pia-details.does-this-relate-to-pia.id" },    // relates to PIA
            { path: "form-pia-details.does-this-relate-to-pia.name" },
            { path: "form-pia-details.vm-pia-grid-reference" },
            { path: "form-pia-details.vm-pia-whereabouts-id" },
            { path: "form-pia-details.vm-pia-exchange-code" },
            { path: "form-pia-details.vm-pia-overhead-underground.id" },
            { path: "form-pia-details.vm-pia-overhead-underground.name" },

            // ERS incident details
            { path: "form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-business-premise-bool.id" },
            { path: "form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-business-premise-bool.name" },
            { path: "form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-drop-cable-rel-bool.id" },
            { path: "form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-drop-cable-rel-bool.name" },
            { path: "form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-cable-type-lov.id" },
            { path: "form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-cable-type-lov.name" },
            { path: "form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-cable-type-detail-text" },

            // RTI Incident details
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-weather-conditions-lov.id" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-weather-conditions-lov.name" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-road-conditions-lov.id" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-road-conditions-lov.name" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-in-dark-bool.id" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-in-dark-bool.name" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-street-lighting-bool.id" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-street-lighting-bool.name" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-nearest-light-int" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-vrn-text" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-vehicle-owner-bool.id" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-vehicle-owner-bool.name" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-driver-details-text" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-police-report-ref-text" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-tow-info-text" },
            { path: "form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-additional-info-text" },

            // Fault location details
            { path: "form-vm-instruction-fault-location-site-details.vm-incident-fault-location" },
            { path: "form-vm-instruction-fault-location-site-details.vm-incident-did-you-attend-site" },
            { path: "form-vm-instruction-fault-location-site-details.vm-incident-site-details" },
            
            // Repair details
            { path: "form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-completed-date" },
            { path: "form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-type-lov.id" },
            { path: "form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-type-lov.name" },
            { path: "form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-details-text" },
            
            // Liability details
            { path: "form-28918794-9f8b-41a4-bcdc-3b3231c1eebb-LiabilityDetails.vm-liability-details-witness-lov.id" },
            { path: "form-28918794-9f8b-41a4-bcdc-3b3231c1eebb-LiabilityDetails.vm-liability-details-witness-lov.name" },
            { path: "form-28918794-9f8b-41a4-bcdc-3b3231c1eebb-LiabilityDetails.vm-liability-details-witness-details-text" },
        ]
    });    
}

/* updateRequest is:
 * {
 *      workItem: { ... },
 *      AspectData: { foo: ... }
 * }
 */
function saveInstruction(id, updateRequest)
{
    return SharedoFetch.put(`/api/v1/public/workItem/${id}`, updateRequest);
}

export default 
{
    getMyInstructions,
    getInstruction,
    getInstructionEnriched,
    saveInstruction,
};
